<template>
   <div>
      <big-title>
         Estados de <span>cuenta</span>.
      </big-title>
      <vx-card>
        <vs-tabs v-model="activeTab" color="#28DE18" alignment="fixed" class="tab-action-btn-fill-container">
            <vs-tab label="Estados de cuenta">
              <div class="vx-row mb-6">
                  <div class="vx-col xxl:w-3/4 w-full">
                    <p><span class="bold">¡No olvides tener a la mano tu Segundo Factor de Autenticación!</span></p>
                    <p class="mt-2">
                      Consulta y descarga los últimos estados de cuenta de RedGirasol en los que encontrarás
                      tus movimientos, inversiones y más información relevante sobre tu participación en la Red.
                      Ante cualquier duda o comentario estaremos para apoyarte a través del chat de la plataforma.</p>
                      <p class="mt-4 bold underline">
                        Entre los días 6 y 7 de cada mes aparecerá disponible tu estado de cuenta.
                      </p>                      
                  </div>
              </div>
              <div class="vx-row mb-6">
                  <div class="vx-col xxl:w-3/4 w-full">
                    <vs-button :disabled="selectedStatements.length <= 0" color="success" @click="downloadZipStatements()">Descargar zip</vs-button>
                  </div>
              </div>
              <vs-table ref="table" class="mt-6" multiple v-model="selectedStatements" :data="statements" pagination stripe :max-items="itemsPerPage" noDataText="No hay datos disponibles">
                <template slot="thead">
                  <vs-th class="bold" sort-key="month">MES</vs-th>
                  <vs-th class="bold">PERIODO</vs-th>
                  <vs-th class="bold">PDF</vs-th>
                  <vs-th class="bold">XML</vs-th>
                </template>

                <template slot-scope="{data}">
                  <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <p class="pl-3 bold">{{ tr.month }} {{tr.year}}</p>
                    </vs-td>
                    <vs-td>
                      <p class="font-medium truncate">{{ tr.start_period }} al {{tr.end_period}}</p>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <vs-button type="line" class="px-2 py-1" @click.stop="downloadPdf(tr)" color="dark" >Descargar</vs-button>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <vs-button v-if="tr.has_xml" type="line" class="px-2 py-1" @click.stop="downloadXml(tr)" color="dark" >Descargar</vs-button>
                      <p v-else>--</p>
                    </vs-td>

                  </vs-tr>
                  </tbody>
                </template>
              </vs-table>
            </vs-tab>
            <vs-tab label="Resumen de retenciones">
              <div class="vx-row mb-6">
                  <div class="vx-col xxl:w-3/4 w-full">
                    <!-- <p><span class="bold">¡No olvides tener a la mano tu Segundo Factor de Autenticación!</span></p> -->
                    <p class="bold">
                      En esta sección puedes consultar el resumen de la información de las retenciones realizadas por algunos Solicitantes relacionadas a los proyectos que invertiste.
                    </p>
                  </div>
              </div>
              <vs-row>
                <vs-col vs-lg="2" vs-sm="6" vs-xs="12">
                    <vs-select
                        class="w-full mb-4"
                        label="Año"
                        v-model.lazy="retainersYearSelected"
                        name="retainersYearSelected"
                        placeholder="Seleccione una opción"
                    >
                        <vs-select-item
                        :key="index"
                        :value="index"
                        :text="item"
                        v-for="(item,index) in years"
                        class="w-full"
                        />
                    </vs-select>
                </vs-col>
                <vs-col vs-lg="7" vs-sm="12" vs-xs="12" class="ml-4">
                    <div class="flex flex-wrap mt-6">
                        <vs-button color="success" @click="getRetainers()">Consultar</vs-button>
                    </div>
                </vs-col>
              </vs-row>
              <vs-table ref="table" class="mt-6" :data="retainers" pagination stripe :max-items="itemsPerPage" noDataText="No hay datos disponibles">
                <template slot="thead">
                  <vs-th class="bold" sort-key="month">RFC RETENEDOR</vs-th>
                  <vs-th class="bold">MONTO DEL INGRESO RECIBIDO</vs-th>
                  <vs-th class="bold">ISR RETENIDO</vs-th>
                  <vs-th class="bold">ÚLT. ACTUALIZACIÓN</vs-th>
                </template>
                <template slot-scope="{data}">
                  <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <p class="bold">{{ tr.rfc }}</p>
                    </vs-td>
                    <vs-td>
                      <p class="font-medium truncate">{{ explicitMoneyFormat(tr.amount) }} </p>
                    </vs-td>
                    <vs-td>
                      <p class="font-medium truncate">{{ explicitMoneyFormat(tr.isr) }} </p>
                    </vs-td>
                    <vs-td>
                      <p class="font-medium truncate">{{ tr.last_update }} </p>
                    </vs-td>
                  </vs-tr>
                  </tbody>
                </template>
              </vs-table>
            </vs-tab>
            <vs-tab label="Facturas de intereses">
              <div class="vx-row mb-6">
                  <div class="vx-col xxl:w-3/4 w-full">
                    <p><span class="bold">¡No olvides tener a la mano tu Segundo Factor de Autenticación!</span></p>
                    <p class="mt-2">
                      Consulta y descarga las facturas de intereses mas recientes, ante cualquier duda o comentario estaremos para apoyarte a través del chat de la plataforma.</p>
                  </div>
              </div>
              <vs-row>
                <vs-col vs-lg="2" vs-sm="6" vs-xs="12">
                    <vs-select
                        class="w-full mb-4"
                        label="Mes"
                        v-model.lazy="invoicesMonthSelected"
                        name="invoicesMonthSelected"
                        placeholder="Seleccione una opción"
                    >
                        <vs-select-item
                        :key="index"
                        :value="index+1"
                        :text="item"
                        v-for="(item,index) in months"
                        class="w-full"
                        />
                    </vs-select>
                </vs-col>
                <vs-col vs-lg="2" vs-sm="6" vs-xs="12" class="ml-2">
                    <vs-select
                        class="w-full mb-4"
                        label="Año"
                        v-model.lazy="invoicesYearSelected"
                        name="invoicesYearSelected"
                        placeholder="Seleccione una opción"
                    >
                        <vs-select-item
                        :key="index"
                        :value="index"
                        :text="item"
                        v-for="(item,index) in years"
                        class="w-full"
                        />
                    </vs-select>
                </vs-col>
                <vs-col vs-lg="7" vs-sm="12" vs-xs="12" class="ml-4">
                    <div class="flex flex-wrap mt-6">
                        <vs-button color="success" class="mr-3" @click="getInvoicesAvailable()">Consultar</vs-button>
                        <vs-button :disabled="selectedInvoices.length <= 0" color="success" @click="downloadZipInvoices()">Descargar zip</vs-button>
                    </div>
                </vs-col>
              </vs-row>
              <!-- <vs-table ref="table" class="mt-6" :data="allInvoices" pagination stripe :max-items="itemsPerPage" noDataText="No hay datos disponibles">
                <template slot="thead">
                  <vs-th>MES</vs-th>
                  <vs-th>FACTURAS</vs-th>
                  <vs-th>INTERESES COBRADOS</vs-th>
                  <vs-th>IVA</vs-th>
                  <vs-th>IVA RETENIDO</vs-th>
                  <vs-th>ISR RETENIDO</vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                </template>
                <template v-if="allInvoices.length > 0" slot-scope="{data}">
                  <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>{{tr.date}}</vs-td>
                      <vs-td>{{tr.total}}</vs-td>
                      <vs-td>{{tr.interests}}</vs-td>
                      <vs-td>{{tr.tax}}</vs-td>
                      <vs-td>{{tr.tax_retention}}</vs-td>
                      <vs-td>{{tr.isr_retention}}</vs-td>
                      <vs-td></vs-td>
                      <vs-td></vs-td>
                      <template slot="expand"> -->
                        <vs-table ref="table" multiple v-model="selectedInvoices" :data="allInvoices" pagination stripe :max-items="itemsPerPage" noDataText="No hay datos disponibles">
                          <template slot="thead">
                            <vs-th></vs-th>
                            <vs-th class="bold">FECHA</vs-th>
                            <vs-th class="bold">ID PROYECTO(S)</vs-th>
                            <vs-th class="bold">INTERESES</vs-th>
                            <vs-th class="bold">IVA</vs-th>
                            <vs-th class="bold">IVA RETENIDO</vs-th>
                            <vs-th class="bold">ISR RETENIDO</vs-th>
                            <vs-th class="bold">PDF</vs-th>
                            <vs-th class="bold">XML</vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <tbody>
                              <vs-tr :data="tri" :key="indextr" v-for="(tri, indextr) in data">
                                <vs-td></vs-td>
                                <vs-td class="text-left">{{tri.invoice_date}}</vs-td>
                                <vs-td class="text-left">{{tri.project_ids}}</vs-td>
                                <vs-td class="text-left">{{explicitMoneyFormat(tri.interests)}}</vs-td>
                                <vs-td class="text-left">{{explicitMoneyFormat(tri.tax)}}</vs-td>
                                <vs-td class="text-left">{{explicitMoneyFormat(tri.tax_retention)}}</vs-td>
                                <vs-td class="text-left">{{explicitMoneyFormat(tri.isr_retention)}}</vs-td>
                                <vs-td class="text-left">
                                  <vs-button v-if="tri.pdf_file" type="line" class="px-2 py-1" @click.stop="downloadPdfInvoice(tri)" color="dark" >Descargar</vs-button>
                                </vs-td>
                                <vs-td class="text-left">
                                  <vs-button v-if="tri.xml_file" type="line" class="px-2 py-1" @click.stop="downloadXmlInvoice(tri)" color="dark" >Descargar</vs-button>
                                </vs-td>
                              </vs-tr>
                            </tbody>
                          </template>
                        </vs-table>
                      <!-- </template>
                    </vs-tr>
                  </tbody>
                </template>
              </vs-table> -->
            </vs-tab>
        </vs-tabs>
      </vx-card>

     <!-- Componente para segundo factor -->
     <two-factor-auth
       :user-id="UserId"
       custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para realizar la descarga del archivo."
       @on-code-validated="onAuth"
       @on-code-invalid="onAuthFailed" />
   </div>

</template>

<script>
import dateHelper from "@mixins/dateHelper";
import formatHelper from "@mixins/formatHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import auth2faHelper from "@mixins/auth2faHelper";
export default {
   data() {
      return {
        activeTab: 0,
        selected: [],
        allStatements: [],
        allRetainers: [],
        statements: [],
        retainers: [],
        allInvoices: [],
        groupedInvoices: [],
        itemsPerPage: 10,
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        isMounted: false,
        currentStatement: null,
        currentInvoice: null,
        authAction: null,
        factorEnabled: false,
        years: Array.from({ length: 4 }, (_, i) => new Date().getFullYear() - i),
        months: ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],
        retainersYearSelected: 0,
        invoicesYearSelected: 0,
        invoicesMonthSelected: new Date().getMonth()+1,
        selectedStatements: [],
        selectedInvoices: [],
      }
   },
  components: {
     TwoFactorAuth
  },
  mixins: [dateHelper, auth2faHelper,formatHelper],
   mounted () {
    this.isMounted = true;
   },
   async beforeMount(){
     this.showLoading(true)
     this.factorEnabled = await this.getUser2FAStatus(this.UserId);
     await this.getStatementsAvailable();
     this.showLoading(false)
   },
   computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    request2faCode(){
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
     async getStatementsAvailable(){
       try {
         const res = await axios.get(`api/v2/investor/${this.InvestorId}/getStatements`);
         this.allStatements = res.data.statements;
         this.allStatements.forEach(f => {
           this.statements.push({
             id: f.id,
             month: this.monthName(f.end_period_month),
             year: f.end_period_year,
             start_period: this.regularDateFormat(f.start_period_date),
             end_period: this.regularDateFormat(f.end_period_date),
             has_xml: f.rg_invoice_id != null
           })
         });
       }
       catch (e) {
       }
     },
     async getRetainers(){
      this.allRetainers = [];
      this.retainers = [];
      this.showLoading(true);
       try {
         let year = this.years[this.retainersYearSelected];
         const res = await axios.get(`api/v2/investor/${this.InvestorId}/getRetainers/${year}`);
         this.allRetainers = res.data.retainers;
         this.allRetainers.forEach(f => {
           this.retainers.push({
             rfc: f.issuer_rfc,
             amount: f.amount,
             isr: f.isr,
             last_update: this.regularDateFormat(f.last_update)
           })
         });
         this.showLoading(false);
       }
       catch (e) {
        this.showLoading(false);
       }
     },
     async getInvoicesAvailable(){
      this.allInvoices = [];
      this.groupedInvoices = [];
      this.showLoading(true);
       try {
          let month = this.invoicesMonthSelected;
          let year = this.years[this.invoicesYearSelected];
          const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvoices/${month}/${year}`);
          this.allInvoices = res.data.invoices;
          this.allInvoices.forEach(f => {
            f.invoice_date = this.regularDateFormat(f.date);
            f.date = this.incomeSpecial(f.date);
          });
          // this.groupInvoices();
          this.showLoading(false);
       }
       catch (e) {
        this.showLoading(false);
       }
     },
    monthName(index){
      return this.monthNames[index -1];
    },
    // groupInvoices(){
    //   this.groupedInvoices = [];
    //   let groups = [];
    //   let group = {};
    //   this.allInvoices.forEach(invoice => {
    //       if (groups.filter(g => g.date === invoice.date).length == 0) {
    //         var dataGroup = this.invoicesAmounts(invoice.date);
    //         group = this.createGroup(invoice.date,dataGroup.interests,dataGroup.tax,dataGroup.tax_retention,dataGroup.isr_retention,dataGroup.count);
    //         groups.push(group);
    //       }
    //       this.groupedInvoices = groups; 
    //   });
    // },
    // createGroup(date,interests,tax,tax_retention,isr_retention,total){
    //   return {
    //     date: date,
    //     interests: this.explicitMoneyFormat(interests),
    //     tax: this.explicitMoneyFormat(tax),
    //     tax_retention: this.explicitMoneyFormat(tax_retention),
    //     isr_retention: this.explicitMoneyFormat(isr_retention),
    //     total: total,
    //   };
    // },
    // invoicesAmounts(date) {
    //   let data = {
    //     count:0,
    //     interests:0,
    //     tax:0,
    //     tax_retention:0,
    //     isr_retention:0
    //   };
    //   this.allInvoices.filter(inv => inv.date === date).forEach(inv => {
    //     data.count +=1;
    //     data.interests += inv.interests;
    //     data.tax += inv.tax;
    //     data.tax_retention += inv.tax_retention;
    //     data.isr_retention += inv.isr_retention;
    //   });
    //   return data;
    // },
    // invoices(date) {
    //     return this.allInvoices
    //         .filter(inv => inv.date === date)
    //         .map(inv => inv);
    // },
    async downloadPdf(tr){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }
       this.currentStatement = tr;
       this.authAction = 1;
       this.request2faCode();
    },
    async downloadXml(tr){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }

      this.currentStatement = tr;
      this.authAction = 2;
      this.request2faCode();
    },
    async downloadPdfInvoice(tr){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }
       this.currentInvoice = tr;
       this.authAction = 3;
       this.request2faCode();
    },
    async downloadXmlInvoice(tr){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }
      this.currentInvoice = tr;
      this.authAction = 4;
      this.request2faCode();
    },
    async downloadZipStatements(){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }
       this.authAction = 5;
       this.request2faCode();
    },
    async downloadZipInvoices(){
      if(!this.factorEnabled){
        this.show2faRequiredMessage();
        return;
      }
       this.authAction = 6;
       this.request2faCode();
    },
    show2faRequiredMessage(){
      this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
    },
    async onAuth(code){
      switch (this.authAction) {
        case 1:
          await this.doDownloadPdf(code);
          break;
        case 2:
          await this.doDownloadXml(code);
          break;
        case 3:
          await this.doDownloadPdfInvoice(code);
          break;
        case 4:
          await this.doDownloadXmlInvoice(code);
          break;
        case 5:
          await this.doDownloadZipStatements(code);
          break;
        case 6:
          await this.doDownloadZipInvoices(code);
          break;
        default:
          break;
      }
    },
    async onAuthFailed(code){
    },
    async doDownloadPdf(code){
      try {
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadStatementPdf/${this.currentStatement.id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
        // se genera log de accion
        this.saveInvestorLogAction("LC12-26");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async doDownloadXml(code){
      try {
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadStatementXml/${this.currentStatement.id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
        // se genera log de accion
        this.saveInvestorLogAction("LC12-26");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async doDownloadPdfInvoice(code){
      try {
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadInvoicePdf/${this.currentInvoice.invoice_id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async doDownloadXmlInvoice(code){
      try {
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadInvoiceXml/${this.currentInvoice.invoice_id}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
      }
      catch (e) {
        this.warn(e)
        this.showError(e);
      }
    },
    async doDownloadZipStatements(code) {
      try {
        let statementsIds = this.selectedStatements.map(a => a.id);
        let idsEncoded = encodeURIComponent(JSON.stringify(statementsIds));
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadZipStatements/${idsEncoded}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
      } catch (e) {
        if (e.response?.data?.message != undefined) {
          this.errorNotif("Aviso", e.response.data.message, 4000);
        } else {
          this.errorNotif("Aviso", 'Parece que ha ocurrido un error, intente más tarde', 4000);
        }
      }
    },
    async doDownloadZipInvoices(code) {
      try {
        let invoicesIds = this.selectedInvoices.map(a => a.invoice_id);
        let idsEncoded = encodeURIComponent(JSON.stringify(invoicesIds));
        let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/downloadZipInvoices/${idsEncoded}?access_token=${this.AccessToken}&2fa_restart_cookie=true&code=${code}`;
        window.open(url, "_blank");
      } catch (e) {
        if (e.response?.data?.message != undefined) {
          this.errorNotif("Aviso", e.response.data.message, 4000);
        } else {
          this.errorNotif("Aviso", 'Parece que ha ocurrido un error, intente más tarde', 4000);
        }
      }
    },
    async goTo2FaPage(){
      await this.$router.push({name: 'auth2FaInversionista'});
    },
    showError(e){
       let err = "Ha ocurrido un error, intenta más tarde.";
       if(e.response && e.response.data && e.response.data.message){
         err = e.response.data.message;
       }
       this.errorNotifDialog('Error en la petición', err);
    },
  }
}
</script>
